import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import HomeDesigns from "../components/home-designs"
import Funding from "../components/funding"

export default function HomeDesignsAndFunding({ data }) {
  const sections = data.sections.relationships.field_sections
  
  const homeDesignsSection = sections[0]
  const homeDesignsBlocks = homeDesignsSection.relationships.field_section_blocks
  
  const fundingSection = sections[1]
  const fundingBlocks = fundingSection.relationships.field_section_blocks
  
  return (
    <Layout>
      <HomeDesigns blocks={homeDesignsBlocks} />
      <Funding blocks={fundingBlocks} />
    </Layout>
  )
}

export const Head = ({ location, data }) => {
  const canonicalUrl = `${process.env.GATSBY_SITE_URL}home-designs-and-funding/`
  const metaDescription = data.sections?.field_metatags?.description ?? ``
  return (
    <>
      <meta name="description" content={metaDescription} />
      <title>Home designs & funding | DEC Housing</title>
      <link rel="canonical" href={canonicalUrl} />
    </>
  )
}

export const query = graphql`
  query HomeDesignsAndFundingPageQuery {
    sections: nodePage(drupal_internal__nid: {eq: 2}) {
      field_metatags {
        description
      }
      relationships {
        field_sections {
          ... on paragraph__section {
            relationships {
              field_section_blocks {
                ...HeaderFields
                ...TwoColumnBlockFields
              }
            }
          }
        }
      }
      title
    }  
  }
`
