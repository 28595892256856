import * as React from "react"
import Header from "./paragraphs/header"
import TwoColumnBlock from "./paragraphs/two-column-block"
import { buildTwoColumns } from "../utils/helpers"

export default function Funding({ blocks }) {

  const header = blocks[0]
  const twoColumnSections = [ blocks[1] ]
  const twoColumns = buildTwoColumns(twoColumnSections)
  
  return (
    <div className="overflow-hidden py-16 bg-gray-50 border-b-4 border-white">
      <div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="divide-y-2 divide-gray-200">
          <Header header={header} />
          <TwoColumnBlock twoColumns={twoColumns} />
        </div>
      </div>
    </div>
  )
}
